import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Howl, Howler} from 'howler';
import {environment} from '../../environments/environment';
import {first} from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    static uid;
    static church;
    static user_name;
    public data: any = {};
    public uidata = null;
    uid: string;
//  private uid: string;
    isVisible = true;
    sound: any;
    amount: any = 650;
    serviceFormAmount = 650
    gAmount: any = 650;
   // baseUrl= 'https://sandbox-ipa.web.app/'
   //  merchantKey =  '46f0cd694581a';
   //  merchantID ='10000100';
   //  liveUrl = 'https://sandbox.payfast.co.za'

    merchantKey =  'wufgkpkal4z0n';
    merchantID = '17649456';
   baseUrl = 'https://ipa.waccsa.co.za/'
    liveUrl = 'https://payfast.co.za'
    functionsUrl = // !environment.production ?
        // 'http://localhost:5001/wacc-test-env/us-central1/':
    'https://us-central1-ipa-system.cloudfunctions.net/'
    emailCoc: any =
       // !environment.production ? 'http//localhost:5001/wacc-test-env/us-central1/sendMailCoc':
            'https://us-central1-ipa-system.cloudfunctions.net/sendMailCoc'
    private apiKey = 'AIzaSyAjIfyGvkQIze9zasplhdITXVB22g4fCsc' ;


    constructor(
        private afAuth: AngularFireAuth,
        public http: HttpClient,
        public afs: AngularFirestore
    ) {
        this.sound = new Howl({

            src: ['https://firebasestorage.googleapis.com/v0/b/waccsa-e8589.appspot.com/o/quite-impressed-565.mp3?alt=media&token=06f27de1-b491-4406-a4d7-1036d047e7c1']
        });

    }

    isAuth() {
        this.afAuth.authState.subscribe(res => {
            return !!(res && res.uid);
        })

    }

    play() {
       this.sound.play()


    }


    emailLink(url, data) {
        if (data) {
            this.http.post(this.emailCoc,
                {
                    url: url,
                    email: data,

                }
            ).subscribe((res: any) => {

            })
        }
        }

    getUserId(): Promise<any> {
        return this.afAuth.authState.pipe(first()).toPromise();
    }

    async getUser() {
        const user = await this.getUserId();
        return this.afs.collection('users').doc(user.uid).valueChanges().pipe(first()).toPromise();


    }


    // This one the top tier of our subscription packages
    // Its a 12 month subscription plan
    // Once users subscribe to this plan they will enjoy a full year access of ad free services on our app

    sendMail(data: any, url) {

        return this.http.post(this.functionsUrl + url, data)
    }


    getListVideos() {
       return   this.http.get('https://www.googleapis.com/youtube/v3/playlistItems?key=' + this.apiKey + '&playlistId=PLYyrD3UZ2rupHxEuLVmZLjwpaDJBwibNC&part=snippet,id&maxResults=70')

    }

    async getUsersD() {
        return    this.afs.collection('users', ref => ref.where('type', '==', 'Plumber').where('status', '==', 'disabled')
            .orderBy('id', 'desc'))
            .valueChanges().pipe(first()).toPromise();
    }



    async getIndustry() {
        return    this.afs.collection('industry', ref => ref
            .orderBy('id', 'desc'))
            .valueChanges().pipe(first()).toPromise();
    }
}
