import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from '../services/auth.service';
import { Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Howl, Howler} from 'howler';


declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [{




        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'dashboard'
    },

    //    {
    //     path: '',
    //     title: 'Content',
    //     type: 'sub',
    //     icontype: 'apps',
    //     collapse: 'components',
    //     children: [
    //         {path: 'add-accounts', title: 'Devotions', ab:'D'},
    //         {path: 'notices', title: 'Notices', ab:'N'}
    //     ]
    // },{
    //     path: '/media',
    //     title: 'Media',
    //     type: 'link',
    //     icontype: 'content_paste',
    //
    // },

    // {
    //     path: '/media' ,
    //     title: 'Programs',
    //     type: 'link',
    //     icontype: 'fitness_center'
    //
    // },
    //
    //
    // {
    //     path: '/submit' ,
    //     title: 'Add',
    //     type: 'link',
    //     icontype: 'add'}
    //
    // }
    //
    //
    // ,
    // {
    //     path: '/articles' ,
    //     title: 'Articles',
    //     type: 'link',
    //     icontype: 'import_contacts'
    //
    // }

    // {
    //     path: '/tables',
    //     title: 'Fundraising',
    //     type: 'sub',
    //     icontype: 'grid_on',
    //     collapse: 'tables',
    //     children: [
    //         {path: 'regular', title: 'Projects', ab:'RT'},
    //         {path: 'extended', title: 'Pledges', ab:'ET'},

    //     ]
    // },
    // {
    //     path: '/charts',
    //     title: 'Testimonies',
    //     type: 'link',
    //     icontype: 'timeline'

    // },{
    //     path: '/calendar',
    //     title: 'Cellgroups',
    //     type: 'link',
    //     icontype: 'date_range'
    // }
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    churchname;
    username;
    comp: any = '';
    user: string;
    type: any;
     uid: string;
    usr: any = '';
    data: any = {};
    msg: any = [];
    panelOpenState = false;
    count: any = null;
    isVisible: any;
    constructor(private router: Router,
                private afs: AngularFirestore,
                private afAuth: AngularFireAuth,
                public shared: AuthService,
                private snack: MatSnackBar,
                ) {

    }



    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };



    ngOnInit() {



        this.menuItems = ROUTES.filter(menuItem => menuItem);

        try {


            this.afAuth.authState.subscribe((res => {
                if (res && res.uid) {
                    this.uid = res.uid


                    this.afs.collection('users').doc(res.uid).valueChanges().subscribe((resp: any) => {
try {

                        this.comp = resp.comp;
                        this.data = resp
                        localStorage.setItem('user_type', this.data.type)
                        localStorage.setItem('coupon', this.data.coupon)

                        this.type = resp.type
                        //  console.log(resp)
                        this.usr = resp.first_name
                        if (!resp.comp) {
                            this.comp = resp.first_name;
                        }

                    } catch (e) {

}

                    });


                    this.afs.collection('industry').doc(res.uid).valueChanges().subscribe((resp: any) => {
                        this.data = resp
                        try {

                            localStorage.setItem('user_type', this.data.type)

                        this.type = resp.type
                        //  console.log(resp)
                        this.usr = resp.company_name;
                        if (!resp.comp) {
                            this.comp = resp.company_name;
                        }} catch (e) {

                        }

                    })
                }
            }))

        } catch (e) {

        }
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            const ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logOut() {
localStorage.clear()
        this.afAuth.signOut().then(() => {
            window.open('/login', '_self')

        })
    }

    openPanel() {
        this.panelOpenState = !this.panelOpenState;
    }
}
